/* Remove the updown / arrows for text boxes */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Css For styling scrollbar for whole body */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f0f0f0;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #fc6f05;
  border-radius: 10px;
}
/* To remove te file upload button on input type=file*/
::-webkit-file-upload-button {
  display: none;
}


body {
  scroll-behavior: smooth;
  
}

h4 {
  user-select: none;
  letter-spacing: 0.1px;
}

/* form helper text css */
.MuiFormHelperText-root {
  font-size: 13px !important;
}

/* button css */
.MuiButton-root {
  margin: 20px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.MuiButton-contained {
  color: #fff !important;
}

/* data grid (! for overlapping calender pop up with navbar) */
.InovuaReactDataGrid {
  z-index: auto !important;
}

/* hidden input(type image) in place of  upload button */
#upload-input {
  width: 100%;
  /* height: 100%; */
  outline: none;
  padding-left: 10px;
  display: none;
}

/* image preview when clicked on image */
.popup-class-image-preview .swal2-html-container {
  margin: 0 !important;
  height: 50vh !important;
  /* overflow: hidden; */
}
.popup-class-image-preview {
  padding: 0 !important;
}

.modal-show {
  animation: toast-show 0.3s;
}

@keyframes toast-show {
  0% {
    transform: scale(0.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

/* global shorthand classes */
.center-full {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-width {
  display: flex;
  justify-content: center;
}
.center-height {
  display: flex;
  align-items: center;
}

/* Dashboard icons container */
.home-icons-container {
  /* display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 14px; */
  display: flex;
}
.home-icons-container div {
  display: flex;
  justify-content: center;
}

/* home icons */
.home-icons {
  width: 150px;
}

/* swal error and notification modal */
.swal2-title {
  font-size: 1.25em !important;
}

.title-class-small {
  font-size: 1.25em !important;
}

.swal2-container {
  z-index: 1360 !important;
}
.swal2-popup {
  max-width: 80% !important;
}

.swal2-close:focus {
  box-shadow: none !important;
}

/* menus (create fieldTeam- promoter, supervisor, assistant supervisor) */
.create-menu .MuiMenuItem-root {
  min-height: auto !important;
}

/* profile menu in navbar */
#top-menu .MuiMenuItem-root {
  min-height: auto !important;
}

/* comboBox autocomplete options */
.MuiAutocomplete-option:nth-child(odd) {
  /* background-color: #fcf9f9; */
  background-color: #ebebeb;
}
.MuiAutocomplete-option:hover {
  /* background-color: #80CBC4 !important; */
  background-color: rgb(200, 207, 207) !important;
}

/* modals (edit password, create/edit userStore in fieldTeam) */
.MuiDialogTitle-root {
  background-color: #4caf50;
  display: flex;
  gap: 10px;
  align-items: center;
  user-select: none !important;
  letter-spacing: 0.25px !important;
  color: #fff !important;
  padding: 10px 16px !important;
  /* 7 */
  box-shadow: 0px 4px 5px -2px rgb(0 0 0 / 20%), 0px 7px 10px 1px rgb(0 0 0 / 14%),
    0px 2px 16px 1px rgb(0 0 0 / 12%) !important;
}

.MuiDialogTitle-root .MuiAvatar-root .MuiSvgIcon-root {
  width: unset !important;
  height: unset !important;
}
.MuiDialogContentText-root {
  padding-top: 24px !important;
  user-select: none !important;
}
.MuiDialogActions-root {
  padding: unset !important;
}

/* tab (fieldTeam) */
.MuiTabPanel-root {
  padding: 4px 0 0 0 !important;
}

.delete-dialog .MuiDialogActions-spacing {
  padding: 0 !important;
}
.alert-dialog .MuiDialogActions-spacing {
  padding: 0 !important;
}
.delete-dialog .MuiDialogTitle-root {
  /* padding-bottom: 7px !important; */
  /* background-color: rgb(233, 67, 67); */
  /* color: #fff */
}

/* alert and confirmation modals(delete and confirmation)  */
.delete-dialog .MuiDialogContent-root {
  padding-top: 7px !important;
}
.alert-dialog .MuiDialogContent-root {
  padding-top: 7px !important;
}

/* store unassigned checkbox in userStore edit modal in fieldTeam */
.store-unassigned-checkbox .MuiFormControlLabel-root {
  flex-direction: row-reverse;
  margin-left: 0 !important;
}

/* modal icons */

.modal-type-1 svg {
  color: #000 !important;
}

/* custom inputs for inventory */
#inventory-input {
  /* height: 1.5rem; */
  font: inherit;
  padding: 8.5px 14px;
  border-radius: 4px;
  border: 1px solid rgb(185, 184, 184);
  outline: none;
  /* background-color: #dce3e6; */
}

#inventory-input:hover {
  border: 1px solid rgba(0, 0, 0, 0.75);
}

#inventory-input:focus {
  border: 1px solid #4caf50;
  outline: 0.75px solid #4caf50;
}

/* error inputs */

.error-inputs {
  border: 0.5px solid #f44336 !important;
  outline: none;
}
.error-inputs:focus {
  /* border: none; */
  /* border: none; */
  border: 1px solid #f44336 !important;
  outline: 0.75px solid #f44336 !important;
}
/* .error-input:active{
 
  border: 1.5px solid red;

} */

.input-helper-texts {
  font-size: 0.75em;
  color: #f44335;
  font-weight: 300;
  line-height: 1.25;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

/* export / import import select */
.upload-import {
  border: 1px rgb(185, 184, 184) solid;
  padding: 7px 14px;
  border-radius: 4px;
  overflow: hidden;
}

.upload-import:hover {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.75);
  color: #4caf50;
}

.import-label {
  border-right: 1px solid gray;
  padding-right: 6px;
}

.upload-import input:hover {
  cursor: pointer;
}

/* In progress page */

.inprogress-container {
  animation: in-progress 32s infinite cubic-bezier(0.68, -0.55, 0.265, 1.55);
  /* animation: 3s ease-in 1s infinite reverse both running slidein; */
}

@keyframes in-progress {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.progress-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: progress-text-animation 16s infinite cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@keyframes progress-text-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.inprogress-root {
  /* animation: root-inprogress-animation 12s ease-in-out 0.5s infinite; */
  animation: root-inprogress-animation 32s infinite cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@keyframes root-inprogress-animation {
  0% {
    background-color: lavender;
  }

  50% {
    background-color: white;
  }
  100% {
    background-color: lavender;
  }
}

/* ... */

/* dashboard filters */
#filters .MuiAutocomplete-inputRoot {
  font-size: 14px !important;
}
#filters .MuiAutocomplete-root label {
  font-size: 14px !important;
}
#analytics .recharts-legend-item-text {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.57) !important;
  font-weight: 500;
}
.MuiDialogContent-root .recharts-legend-item-text {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.57) !important;
  font-weight: 500;
}
/* just for viewing, no function */
.test {
  /* chart 1 */
  color: #1c4e80;
  color: #0091d5;
  /* chart 2 */
  color: #0cb4b2;
  /* chart 3 */
  color: #51c882;
  color: #7bf4ac;
  /* chart 4 */
  color: #ac3e31;
  color: #488a99;
  /* chart 5 */
  color: #009185;
  color: #d32d41;
}
/* slider in dashboard */

.swiper {
  width: 100%;
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  /* opacity: .85; */
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ........................................................................................................... */

.sign-in-form label{
  /* font-size: 16px;
  font-weight: 500; */
  color: black !important;
}
/* .sign-in-form label:active{
 color: #000 !important;
} */

.question-handle {
  cursor: move;
  cursor: grab;
}
.question-handle:active {
  cursor: move;
  cursor: grabbing;
}

.welcome-space {
  /* margin: 10px; */
  /* z-index: 2; */
  /* background-color: #fca25f; */
  /* background-image: url('./assets/images/OrangesScreen.jpg'); */
}

.sign-in-root {
  /* background-color: #fca25f; */
  background-image: url('./assets/images/OrangesScreen.jpg');

}

.menu-item{
 cursor: pointer;
 transition: all ease-in 0.3s;
}
.menu-item:hover{
  /* font-size: 20px ; */
  color: #000;
 }

/* formModals */
.form-modal .MuiDialogContent-root{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* ........................................................................................................... */

/* media queries */
@media screen and (max-width: 1050px) {
  /* home icon grid layout */
  .home-icons-container {
    grid-template-columns: repeat(4, auto);
  }
}
@media screen and (max-width: 1000px) {
  /* calender popper for smaller width */
  .MuiPickersDay-root {
    font-size: 16px !important;
  }
  .MuiCalendarPicker-root .MuiTypography-caption {
    font-size: 12px !important;
  }

  /* 404 error page */
  .error404-logo1 {
    width: 150px !important;
  }
}

@media screen and (max-width: 900px) {
  /* home icon grid layout */
  .home-icons-container {
    grid-template-columns: repeat(3, auto);
  }
}

@media screen and (max-width: 800px) {
  #inventory-input {
    padding: 8.5px 7px;
  }
}

@media screen and (max-width: 650px) {
  /* home icon container */
  .home-icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  /* 404 error page asteroid logo */
  #asteroid-404 {
    width: 400px !important;
  }

  /* export button fonts in react data grid  */
  .inovua-react-toolkit-button__text {
    font-size: 11px !important;
  }

  /* profile menu in navbar */
  #top-menu .MuiListItemIcon-root {
    min-width: 30px !important;
  }

  /* action buttons in forms (save, delete, cancel) */
  .action-buttons button {
    margin: 10px !important;
  }
}

@media screen and (max-width: 500px) {
  html {
    font-size: 12px;
  }

  /* swal modal and notifications */
  .swal2-title {
    font-size: 1em !important;
  }
  .swal2-popup {
    width: 20em !important;
  }

  /* 404 error page asteroid logo */
  #asteroid-404 {
    width: 300px !important;
  }

  /* comboBox autocomplete options */
  .MuiAutocomplete-option {
    min-height: unset !important;
  }

  /* .MuiAutocomplete-listbox{
  gap:10px !important; 
  } */
}

@media screen and (max-width: 480px) {
  /* toastify toasts */

  /* .toast-container-responsive {
    width: 270px !important;
    /* right:0 !important; */
  /* left:100 !important; */
  /* margin:5px !important; */
  /* } */

  .Toastify__toast {
    margin-left: 32px !important;
    border-radius: 4px !important;
    margin-bottom: 8px !important;
  }
  .Toastify__toast-container {
    left: unset !important;
    right: 0 !important;
    padding: 8px !important;
  }
  /* signIn page Dhanush logo */
  .sign-in-logo {
    height: 70% !important;
  }
  /* form radio inputs */
  .MuiRadio-root {
    padding: 4px !important;
  }
  /* 404 error page */
  .error404-logo2 {
    width: 50px !important;
  }
}

/* @media screen and (max-width: 370px) {
 

} */
